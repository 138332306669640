import React from "react";

export interface IAuthData {
	succeeded: boolean,

	accessToken?: string,
	userData?: IUserData;
	rememberMe: boolean;
}

export interface IUserData {
	"IdUser": string,//"34ddf48c-eb12-4270-ba0a-39f6e3699417",
	"FullName": string,//"Fabric Editor",
	"Name": string,//"editor",
	"Email": string,//"email@sense-immaterialreality.com",
	//"Disabled": boolean,
	//"Prop1": string, //"Company",
	//"Prop2": string, //"Role",
	//"Prop3": BoolType, //"WorkWith3D",
	"IdUserType": EIdUserType,
	"IdProject": string,//"9f39067e-0547-46e8-bf1d-e33cb5e1d95a",
	"ProjectName": string,//"Fabric Sense-IR"
}

export enum EIdUserType {
	Admin = 0,
	BaseUser = 1,
	SuperAdmin = 2,
	Seller = 3,
	Operator = 4,

	//Visitor = 6, //Not used
	Restricted = 8,
}

export interface IAuthContext {
	auth?: IAuthData,
	setAuth?: React.Dispatch<React.SetStateAction<IAuthData>>,
}

export type LoginData = {
	UserName: string,
	Password: string,
	rememberMe: boolean,
}

export type BoolType = "TRUE" | "FALSE";

export interface IRequestAccessRequest {
	"FullName": string, //"Nome Cognome",
	"Name": string, //"username1",
	//"Password": string, //"Abcdefg1!",
	"Email": string,//"mail@gmail.com",
	"Prop1": string, //"Company",
	"Prop2": string, //"Role",
	"Prop3": BoolType, //"WorkWith3D"
}

export interface IRequestAccessResponse {
	"Message": string, //"Name 'username1' already exists"
	"Error": boolean, //"username1",
	"StatusCode": ERequestAssessCode, //"200 or 401.. etc..",
}

export enum ERequestAssessCode {
	Ok= 200,
	UsernameDuplicated= 401,
	PasswordErrata= 402,
	MissingData= 403,
	ServerError= 500,
}

export interface IGrantAccessResponse {
	"Message": string, //"Name 'username1' already exists"
	"Error": boolean, //"username1",
	"StatusCode": EGrantAssessCode, //"200 or 401.. etc..",
}

export enum EGrantAssessCode {
	Ok= 200,
	//AlreadyActivated= 401, //not supported by server since it deletes the codes from db
	CodeNotValid= 402,
	ServerError= 500,
}

export interface IPasswordChangeBody {
	"OldPassword": string;
	"NewPassword": string;
}