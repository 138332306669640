import React, { useEffect, useState } from "react";
import styled, {useTheme} from "styled-components";
import { useTranslation } from "react-i18next";

import { LoginData } from "types";
import { InputField } from "components/inputField/inputField";
import { Button } from "components/button/button";
import {Toggle} from "../../../components/toggle/toggle";
import {useNavigate} from "react-router-dom";


const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 320px;
	align-items: center;
	padding: 70px 30px 30px 30px;
	gap: 20px;
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
`;

const ErrorMessage = styled(Row)`
	text-align: center;
	justify-content: center;
	color: ${props => props.theme.Negative};
	height: 14px;
`;

const Label = styled.label`
	display: flex;
	flex-direction: row;
	padding-left: 22px;
	color: ${props => props.theme.TextDefault};
`;

const RequestAccessLabel = styled.label`
	color: ${props => props.theme.TextLight};
	cursor: pointer;
	text-decoration: underline;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;

const PublicAccessLabel = styled.p`
	color: ${props => props.theme.TextDefault};
	cursor: pointer;
	text-decoration: underline;
	text-align: center; !important;
	
	transition: color easeout 300ms;
	&:hover {
		color: ${props => props.theme.TextDark};
	}
`;


export const LoginCard = ({ onSignIn, isLoading = false, error }: LoginCardProps) => {

	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();

	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [shouldRemember, setShouldRemember] = useState(false);
	const [errorMessage, setErrorMessage] = useState(error);

	useEffect(() => setErrorMessage(error), [error]);

	useEffect(() => {
		const keyDownHandler = (event: KeyboardEvent) => {

			if (event.key === "Enter") {
				event.preventDefault();
				handleSignIn();
			}
		};

		document.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, [username, password, shouldRemember]);

	const handleSignIn = () => {
		onSignIn({ UserName: username, Password: password, rememberMe: shouldRemember });
	};

	const canSubmit = username.length > 0 && password.length > 0;

	const handleOnChange = (field: "code" | "user" | "psw", value: string) => {

		field === "user" && setUsername(value);
		field === "psw" && setPassword(value);

		setErrorMessage("");
	};

	return (
		<Wrapper>
			<Column>
				<Label className="heading-s">{t("Email")}</Label>
				<InputField id="username"
					size="Default"
					width="100%"
					backgroundColor={theme.Gray1}
					value={username}
					onChange={value => handleOnChange("user", value)}
				/>
			</Column>
			<Column>
				<Label className="heading-s">{t("Password")}</Label>
				<InputField
					id="password"
					type={showPassword ? "text" : "password"}
					size="Default"
					width="100%"
					backgroundColor={theme.Gray1}
					value={password}
					rightIcon={!showPassword ? "/assets/view.svg" : "/assets/view-off.svg"}
					iconSizeOverride={"32px"}
					onRightIconClick={() => setShowPassword(!showPassword)}
					onChange={value => handleOnChange("psw", value)}
					isInvalid={!!password.length && !!errorMessage}
				/>
				<Row style={{ marginTop: "20px", paddingLeft: "14px"}}>
					<Toggle label={t("RememberMe")} checked={shouldRemember} onChange={setShouldRemember} labelPosition={"right"}/>
				</Row>
			</Column>
			<Row>
				<ErrorMessage className="text-s">{!!password.length && !!errorMessage && (
					errorMessage === "401" ? t("login_disabled") :
					(errorMessage === "404" ? t("login_error") : t("ServerError"))
				)}</ErrorMessage>
			</Row>
			<Row style={{ marginTop: "10px", width: "120px" }}>
				<Button
					label={t("SignIn")}
					backgroundColor={"TextDark"}
					color={"Gray0"}
					hoverDark={false}
					onClick={handleSignIn}
					isLoading={isLoading}
					isDisabled={!canSubmit}
					shouldFill
					type="submit"
				/>
			</Row>

			<div style={{margin: "20px 0px"}}>
				<PublicAccessLabel onClick={() => navigate("/categories?publicAccess=true")}>{t("PublicAccess")}</PublicAccessLabel>
			</div>

			<RequestAccessLabel onClick={() => navigate("/requestAccess")}>{t("RequestAccess")}</RequestAccessLabel>
			<RequestAccessLabel onClick={() => navigate("/forgotPassword")}>{t("PasswordForgotten")}</RequestAccessLabel>
		</Wrapper>
	);
};

type LoginCardProps = {
	onSignIn: (payload: LoginData) => void,
	isLoading?: boolean,
	error?: string | null, //code string 200 ok, 401 utente nn abilitato, 500 invalid credential
}