import React, {useEffect, useState} from "react";
import styled, {useTheme} from "styled-components";

import { useTranslation } from "react-i18next";
import {Button} from "../../components/button/button";
import {
	FileDescriptor,
	ICategoryWebBundle,
	IGeneralColor,
	ITechPropertyPreset,
	TypeDownload
} from "../../types/categories";
import {useDebounce} from "../../utils/debounce";
import {Group, RowFlexGrowJustifyEnd, Stack} from "../../components/common";
import {Icon} from "../../components/icon/icon";
import {ModelCodeToString, TechCodeArray, UniquePerformances, UniquePerfToFile} from "../../types";
import Slider from "rc-slider";
import useIsPublicUser from "../../hooks/useIsPublicUser";

const FullScreenBackground = styled.div`
    position: absolute;
	inset: 0;
	z-index: var(--z-level-5);
	
	width: 100%;
	height: 100%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//padding: 160px 400px 50px 50px;
	gap: 0px;
	
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

const MainWrapper = styled.div`
	position: relative;
	
	display: flex;
	flex-direction: column;
	
	justify-content: flex-start;
	//align-items: center;
	gap: 35px;
	padding: 20px;

	min-height: min(450px, 90vh);
	max-height: 800px;
	min-width: 600px;
	width: 800px;
	max-width: 800px;
	@media screen and (max-width: 860px) {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	background-color: ${props => props.theme.Background};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
`;

const Title = styled.p`
	text-align: center;
	color: ${props => props.theme.TextDark};
`;

const WrapContainer = styled.div`
	flex: 0 0 auto;
	
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;

	overflow-y: scroll;
	max-height: 450px;
		
	gap: 16px;
	//padding: 25px 10px;
`;

const IconImage = styled.div<{src: string}>`
	width: 24px;
	min-width: 24px;
	height: 24px;
	cursor: pointer;

	background-image: url("${props => props.src}");
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	background-position-y: center;

	opacity: 0.5;

	transition: opacity 300ms;
	&:hover {
		opacity: 1;
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	padding: 15px;
	gap: 16px;
	
	border-radius: 4px;
	border: 1px solid ${props => props.theme.TextLight};
	cursor: pointer;

	transition: border background-color 300ms;
	&:hover {
		border: 1px solid ${props => props.theme.TextDefault};
	}
	
	background-color: #FFFFFF;
	&.selected {
		background-color: ${props => props.theme.Gray2};
		//background-color: ${props => props.theme.Accent};
	}
	
	//font-size: 20px;
	width: 110px;
	//height: 130px;
	//max-height: 110px;
`;

const Image = styled.div<{src: string}>`
	height: 50px;
	min-height: 50px;
	width: 50px;
	
	border-radius: 50%;
	
	background-image: url("${props => props.src}");
	background-repeat: no-repeat;
	background-size: 50px;
	background-position-x: center;
	background-position-y: center;
`;

const Color = styled.div<{hex: string}>`
	height: 50px;
	min-height: 50px;
	width: 100px;
	
	border-radius: 4px;
	background-color: ${props => props.hex};
`;

const Text = styled.div`
	flex: 0 1 auto;
	text-align: center;
	
	color: ${props => props.theme.TextDark};
`;

const TextDe = styled.div`
	flex: 0 1 auto;
	text-align: center;
	
	color: ${props => props.theme.TextDefault};
`;

const FooterRow = styled.div`
	display: flex;
	flex-direction: row;
	//justify-content: space-between;
	align-items: center;
	gap: 15px;

	@media screen and (max-width: 860px) {
		//flex-direction: column;
		//gap: 15px;

		gap: 10px;
		
		//height: ;
	}
`;

const FooterWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	
	padding: 5px;
	border-radius: 4px;
	gap: 10px;
	max-width: 70px;
	
	cursor: pointer;

	transition: background-color 300ms;
	background-color: #ffffff00;
	
	&:hover {
		background-color: ${props => props.theme.Gray2};
	}
`;

const FooterItem = styled.div`
	width: 28px;
	height: 28px;
	
	display: flex;
	justify-content: center;
	align-items: center;
	
	padding-left: 1px;
	padding-bottom: 3px;
	
	border-radius: 50%;
	background-color: ${props => props.theme.Gray1};
	
	transition: background-color 300ms;
	&.selected {
		background-color: ${props => props.theme.Accent};
	}
`;

const FooterText = styled.div`
	flex: 0 1 auto;
	text-align: center;
	
	height: 30px;
	min-height: 30px;
	color: ${props => props.theme.TextDark};
`;

const Dash = styled.div`
	flex: 1 1 auto;

	border-bottom: 1px dashed ${props => props.theme.TextLight};
`;



const minDefaultWeight = 50;
const maxDefaultWeight = 400;

const SearchWizardDialog = ({ requestClose, onSearchStart, webBundles, techPropertiesPresets, totalColors }: IProps) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const isPublicUser = useIsPublicUser();

	const [step, setStep] = useState<number>(0);

	const [colors, setColors] = useState<string[]>([]);
	const [techProperties, setTechProperties] = useState<string[]>([]);
	const [clothingTypes, setClothingTypes] = useState<string[]>([]);
	const [modelsIds, setModelsIds] = useState<string[]>([]);
	const [weightFilter, setWeightFilter] = useState<number[]>([minDefaultWeight, maxDefaultWeight]);

	const prevStep = () => {
		if(step > 0)
		{
			setStep(step - 1);
		} else {
			requestClose();
		}
	}

	const nextStep = () => {
		if( (step < 4 && !isPublicUser) || (step < 3 && isPublicUser)) //Skip last color step for public user
		{
			setStep(step + 1);
		} else {
			onSearchStart(colors, techProperties, clothingTypes, modelsIds, weightFilter);
		}
	}

	return (
		<FullScreenBackground>
			<MainWrapper>
				<Group style={{gap: "15px"}}>
					<IconImage src={"/assets/back.svg"} onClick={prevStep}/>
					<Title className={"heading-s"}>{t(`Step${step}_Title`)}</Title>
					<RowFlexGrowJustifyEnd>
						<IconImage src={"/assets/close.svg"} onClick={requestClose}/>
					</RowFlexGrowJustifyEnd>
				</Group>

				{step === 0 &&
					<Stack style={{justifyContent: "center", alignItems: "center", flex: "1 0 auto", gap: "20px"}}>
						<Text className={"text-l"}>{t("Step0Text")}</Text>
						<Text className={"text-l"}>{t("Step0Text1")}</Text>

						<div style={{flex: "1 0 auto"}}></div>
						<Button backgroundColor={"Accent"} color={"TextDark"} /*leftIcon={"back"}*/ caps={false}
								size={"Default"}
								label={t("BeginWizard")}
								onClick={nextStep}
						></Button>
					</Stack>
				}

				{step === 1 &&
					<Stack style={{gap: "50px"}}>
						<WrapContainer style={{justifyContent: "center", width: "100%"}}>
							{TechCodeArray?.map(key =>
								<Item key={key} className={clothingTypes.find(id => id === key) ? "selected" : ""} style={{height: "60px"}}
									onClick={() => {
										const findIndex = clothingTypes.findIndex(id => id === key)
										if(findIndex >= 0) {
											clothingTypes.splice(findIndex, 1);
											setClothingTypes([...clothingTypes]);
										} else {
											setClothingTypes([...clothingTypes, key]);
										}
									}}>
									{/*<Image src={webBundle.Image}/>*/}
									<Text className={"text-l"}>{ModelCodeToString(key)}</Text>
								</Item>
							)}
						</WrapContainer>
						<Stack style={{width: "100%", justifyContent: "center", gap: "15px"}}>
							<Slider
								//style={{ width: "100%", marginLeft: "5px", marginRight: "5px" }}
								style={{ width: "calc(100% - 40px)", marginLeft: "20px", marginRight: "20px" }}
								//className={"mobileStretch2"}
								range
								value={weightFilter}
								onChange={(values) => {
									setWeightFilter(values as number[]);
								}}
								min={minDefaultWeight}
								max={maxDefaultWeight}
							/>
							<Group style={{paddingTop: "4px", width: "100%", justifyContent: "center"}} >
								<TextDe>{`${weightFilter[0]} - ${weightFilter[1]}`} g/m2</TextDe>
							</Group>
						</Stack>
					</Stack>
				}
				{step === 2 &&
					<WrapContainer>
						{webBundles?.map(webBundle =>
							<Item key={webBundle.IdWebBundle} style={{height: "110px"}}
								  className={modelsIds.find(id => id === webBundle.IdWebBundle) ? "selected" : ""}
								  onClick={() => {
									  const findIndex = modelsIds.findIndex(id => id === webBundle.IdWebBundle)
									  if(findIndex >= 0) {
										  modelsIds.splice(findIndex, 1);
										  setModelsIds([...modelsIds]);
									  } else {
										  setModelsIds([...modelsIds, webBundle.IdWebBundle]);
									  }
								  }}>
								<Image src={webBundle.Image}/>
								<Text className={"text-l"}>{webBundle.Name.substring(webBundle.Name.indexOf("_")+1)}</Text>
							</Item>
						)}
					</WrapContainer>
				}
				{step === 3 &&
					<WrapContainer>
						{techPropertiesPresets?.map(tech =>
							<Item key={tech.Value} style={{height: "130px"}}
								  className={techProperties.find(id => id === tech.Value) ? "selected" : ""}
								  onClick={() => {
									  const findIndex = techProperties.findIndex(id => id === tech.Value)
									  if(findIndex >= 0) {
										  techProperties.splice(findIndex, 1);
										  setTechProperties([...techProperties]);
									  } else {
										  setTechProperties([...techProperties, tech.Value]);
									  }
								  }}>
								{<Image src={UniquePerfToFile(tech.Name)}/>}
								<Text className={"text-l"}>{t(tech.Name)}</Text>
							</Item>
						)}
					</WrapContainer>
				}
				{step === 4 &&
					<WrapContainer>
						{totalColors?.map(color =>
							<Item key={color.Value} style={{height: "60px"}}
								  className={colors.find(id => id === color.Value) ? "selected" : ""}
								  onClick={() => {
									  const findIndex = colors.findIndex(id => id === color.Value)
									  if(findIndex >= 0) {
										  colors.splice(findIndex, 1);
										  setColors([...colors]);
									  } else {
										  setColors([...colors, color.Value]);
									  }
								  }}>
								<Color hex={color.Data}/>
								{/*<Text className={"text-l"}>{color.Name.substring(color.Name.indexOf("_")+1)}</Text>*/}
							</Item>
						)}
					</WrapContainer>
				}


				{step != 0 &&
					<>
						<div style={{flex: "1 0 auto"}} ></div>
						<RowFlexGrowJustifyEnd style={{gap: "10px", flex: "0 0 auto"}}>
							{/*<Button backgroundColor={"Gray3"} color={"Gray0"} label={t("Skip")} caps={false} size={"Compact"}
									onClick={() => {}}
							></Button>*/}
							<Button backgroundColor={"Accent"} color={"TextDark"} /*leftIcon={"back"}*/ caps={false} size={"Compact"}
									label={(step < 4 && !isPublicUser) || (step < 3 && isPublicUser) ? t("NextStep") : t("StartSearch")}
									onClick={nextStep}
							></Button>
						</RowFlexGrowJustifyEnd>
					</>
				}

				<FooterRow>
					<FooterWrapper onClick={() => setStep(0)}>
						<FooterItem className={step === 0 ? "selected": ""}>
							<Text className={"text-l"}>1</Text>
						</FooterItem>
						<FooterText className={"text-l"} style={{textAlign: "center"}}>{t("Step0_Footer")}</FooterText>
					</FooterWrapper>
					<Dash/>
					<FooterWrapper onClick={() => setStep(1)}>
						<FooterItem className={step === 1 ? "selected": ""}>
							<Text className={"text-l"}>2</Text>
						</FooterItem>
						<FooterText className={"text-l"} style={{textAlign: "center"}}>{t("Step1_Footer")}</FooterText>
					</FooterWrapper>
					<Dash/>
					<FooterWrapper onClick={() => setStep(2)}>
						<FooterItem className={step === 2 ? "selected": ""}>
							<Text className={"text-l"}>3</Text>
						</FooterItem>
						<FooterText className={"text-l"} style={{textAlign: "center"}}>{t("Step2_Footer")}</FooterText>
					</FooterWrapper>
					<Dash/>
					<FooterWrapper onClick={() => setStep(3)}>
						<FooterItem className={step === 3 ? "selected": ""}>
							<Text className={"text-l"}>4</Text>
						</FooterItem>
						<FooterText className={"text-l"} style={{textAlign: "center"}}>{t("Step3_Footer")}</FooterText>
					</FooterWrapper>

					{ !isPublicUser &&
						<>
							<Dash/>
							<FooterWrapper onClick={() => setStep(4)}>
								<FooterItem className={step === 4 ? "selected": ""}>
									<Text className={"text-l"}>5</Text>
								</FooterItem>
								<FooterText className={"text-l"} style={{textAlign: "center"}}>{t("Step4_Footer")}</FooterText>
							</FooterWrapper>
						</>
					}
				</FooterRow>

			</MainWrapper>
		</FullScreenBackground>
	);
};

interface IProps {
	requestClose: () => void,
	onSearchStart: (
		newColors: string[],
		newTechProperties: string[],
		newClothingTypes: string[],
		newModelsIds: string[],
		newWeightFilter: number[],
	) => void,
	webBundles: ICategoryWebBundle[] | undefined;
	techPropertiesPresets: ITechPropertyPreset[] | undefined;
	totalColors: IGeneralColor[] | undefined,
}

export default SearchWizardDialog;