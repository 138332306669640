import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "../icon/icon";

const DOTS = -1;

const Wrapper = styled.ul`
	position: relative;
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 4px;
`;

const PageItem = styled.li<{ isCurrent: boolean }>`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	background-color: ${({ isCurrent, theme }) =>
		isCurrent ? theme.Gray2 : theme.Gray0};
	color: ${({ isCurrent, theme }) => (isCurrent ? theme.Gray0 : theme.Gray3)};
	transition: all ease-out 200ms;
	user-select: none;

	&:not(.isDot) {
		cursor: pointer;

		&:hover {
			background-color: ${({ theme }) => theme.Gray1};
			color: ${({ theme }) => theme.Gray3};
		}
	}
`;

const Arrows = styled.div<{ disabled: boolean }>`
	display: flex;
	align-items: center;
	stroke: ${props => (props.disabled ? props.theme.Gray1 : props.theme.Gray2)};
	pointer-events: ${props => (props.disabled ? "none" : undefined)};
	cursor: pointer;

	&:hover {
		stroke: ${props => props.theme.Primary};
	}
`;

export const Pagination = ({
	totalPages = 1,
	currentPage = 1,
	siblingCount = 2,
	onChange,
	enabled = true
}: PaginationProps) => {
	const [paginationRange, setPaginationRange] = useState<(number)[]>([]);

	useEffect(() => {
		const range = (start: number, end: number): number[] => {
			const length = end - start + 1;
			return Array.from({ length }, (_, i) => i + start);
		};

		const totalPageNumbers = 5 + siblingCount;

		if (totalPageNumbers >= totalPages) {
			setPaginationRange(range(1, totalPages));
		} else {
			const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
			const rightSiblingIndex = Math.min(
				currentPage + siblingCount,
				totalPages
			);

			const hasLeftDots = leftSiblingIndex > 2;
			const hasRightDots = rightSiblingIndex < totalPages - 2;

			if (!hasLeftDots && hasRightDots) {

				const leftItemCount = 3 + 2 * siblingCount;
				const leftRange = range(1, leftItemCount);
				setPaginationRange([...leftRange, DOTS, totalPages]);

			} else if (hasLeftDots && !hasRightDots) {

				const rightItemCount = 3 + 2 * siblingCount;
				const rightRange = range(totalPages - rightItemCount + 1, totalPages);
				setPaginationRange([1, DOTS, ...rightRange]);

			} else if (hasLeftDots && hasRightDots) {

				const middleRange = range(leftSiblingIndex, rightSiblingIndex);
				setPaginationRange([1, DOTS, ...middleRange, DOTS, totalPages]);
			}
		}
	}, [totalPages, currentPage, siblingCount]);

	const isFirstPage = currentPage === 1;
	const isLastPage = currentPage === totalPages;

	const NextPage = currentPage + 1 > totalPages ? totalPages : currentPage + 1;
	const PrevPage = currentPage - 1 < 1 ? 1 : currentPage - 1;

	const onNext = () => onChange?.(NextPage);
	const onPrev = () => onChange?.(PrevPage);

	//console.log("pagination is", enabled);

	return (
		<Wrapper className={`text ${enabled ? "" : "disabled"}`}>
			<Arrows disabled={isFirstPage} onClick={onPrev}>
				<Icon
					name="leftArrow"
					size="18px"
					strokeWidth={1.5}
					type="TextDefault"
					colorOverride={"inherites"}
				/>
			</Arrows>
			{paginationRange.map((pageNumber, i) => (
				<PageItem
					key={i}
					isCurrent={pageNumber === currentPage}
					onClick={() => onChange?.(pageNumber)}
					className={pageNumber === DOTS ? "isDot" : undefined}
				>
					{pageNumber === -1 ? "..." : pageNumber}
				</PageItem>
			))}
			<Arrows disabled={isLastPage} onClick={onNext}>
				<Icon
					name="rightArrow"
					size="18px"
					strokeWidth={1.5}
					type="TextDefault"
					colorOverride={"inherites"}
				/>
			</Arrows>
		</Wrapper>
	);
};

type PaginationProps = {
	totalPages: number;
	currentPage: number;
	siblingCount: number;
	onChange: (page: number) => void;
	enabled?: boolean;
};
