import useAuth from "./useAuth";
import {EIdUserType, IAuthData} from "../types";
import {useEffect, useState} from "react";


const useIsPublicUser = () => {
	const { auth } = useAuth();

	const getIsPublicUser = (auth?: IAuthData) => {
		return auth?.userData?.IdUserType === EIdUserType.Restricted;
	}

	const [isPublicUser, setIsPublicUser] = useState<boolean>(getIsPublicUser(auth));

	useEffect( () => {
		const newCan = getIsPublicUser(auth);
		//console.log("Can show cart", newCan, auth)

		setIsPublicUser(newCan);

	}, [auth]);

	return isPublicUser;
};

export default useIsPublicUser;