import {Route, Routes, Navigate, useLocation, useSearchParams, useNavigate} from "react-router-dom";

import useAuth from "./hooks/useAuth";
import { App } from "./app";
import {Login, Products, Product, Page404, CredentialSignIn} from "./pages";
import {RequestAccess} from "./pages/requestAccess/requestAccess";
import {GrantAccess} from "./pages/grantAccess/grantAccess";
import {CookiesPolicy} from "./pages/Policies/CookiesPolicy";
import {PrivacyPolicy} from "./pages/Policies/PrivacyPolicy";
import ResetPassword from "pages/resetPassword/resetPassword";
import ForgotPassword from "pages/ForgotPassword/ForgotPassword";
import ChangePassword from "pages/changePassword/changePassword";
import {Categories} from "./pages/categories/categories";
import {Cart} from "./pages/cart/cart";
import {useEffect, useState} from "react";
import useIsPublicUser from "./hooks/useIsPublicUser";
import {useMutation} from "react-query";


function ProtectedRoute ({ outlet }: { outlet: JSX.Element }) {

	//const location = useLocation();

	const { auth, setAuth } = useAuth();
	const navigate = useNavigate();
	const isPublicUser = useIsPublicUser();

	const [searchParams, useSearchParam] = useSearchParams();

	const publicAccessQueryValue = searchParams.get("publicAccess") === "true";
	//const [publicAccessParam, setPublicAccessParam] = useState<boolean>(publicAccessQueryValue === "true");
	useEffect(() => {
		searchParams.set("publicAccess", publicAccessQueryValue || isPublicUser ? "true" : "false");
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useSearchParam(searchParams, { replace: true });
	}, [publicAccessQueryValue, isPublicUser]);

	useEffect(() => {
		//console.log("afwa", auth, publicAccessQueryValue, isPublicUser);

		if(!auth?.succeeded && publicAccessQueryValue) {
			SignInMutation.mutate({
				UserName: "europublicuser",
				Password: "3ur0J3r53yPubl1cZS!",
				rememberMe: false,
			});
		}
	}, [auth, publicAccessQueryValue]);

	const SignInMutation = useMutation(CredentialSignIn);

	useEffect(() => {
		if (!SignInMutation.isSuccess)
			return;

		if ( setAuth) {
			setAuth({succeeded: true, accessToken: SignInMutation.data.token, rememberMe: SignInMutation.data.rememberMe, userData: SignInMutation.data.respData.Data});
		}

	}, [SignInMutation.isSuccess]);


	if (auth?.succeeded)
		return outlet;
	else if(SignInMutation.isLoading)
		return <></>
	else {
		//return <Navigate to={{ pathname: "/" }} replace state={location} />;
		return <Login />
	}
};

function RouterPage () {
	return (
		<Routes>
			<Route path="/" element={<App />}>
				<Route index element={<Login />} />
				<Route path="login" element={<Login />} />
				<Route path="requestAccess" element={<RequestAccess/>} />
				<Route path="grantAccess/:code" element={<GrantAccess/>} />

				<Route path="forgotPassword"  element={<ForgotPassword/>} />
				<Route path="resetPassword/:code" element={<ResetPassword/>} />

				<Route path="cookiesPolicy" element={<CookiesPolicy/>} />
				<Route path="privacyPolicy" element={<PrivacyPolicy/>} />

				<Route path="changePassword" element={< ProtectedRoute outlet={<ChangePassword />} />} />

				<Route path="categories" element={< ProtectedRoute outlet={<Categories />} />} />
				<Route path="categories/:externalCategoryId" element={< ProtectedRoute outlet={<Categories />} />} />
				<Route path="products/:externalCategoryId" element={< ProtectedRoute outlet={<Products searchArticles={false}/>} />} />
				<Route path="products/product/:externalProductId" element={<ProtectedRoute outlet={<Product />} />} />
				<Route path="cart" element={<ProtectedRoute outlet={<Cart />} />} />

				<Route path="categories/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="categories/:externalCategoryId/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="products/:externalCategoryId/search" element={< ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="products/product/:externalProductId/search" element={<ProtectedRoute outlet={<Products searchArticles={true}/>} />} />
				<Route path="cart/search" element={<ProtectedRoute outlet={<Products searchArticles={true}/>} />} />

				<Route path="*" element={<Page404 />} />
			</Route>
		</Routes>
	);
}

export default RouterPage;
